<template>
  <div class="page">
    <div class="page-header">信息监测</div>
    <div class="monitorbox">
      <div class="monitorbox-l">
        <div class="monitorbox-l-head">
          <el-select v-model="value" clearable placeholder="请选择" style="width: 34%">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input class="m-l-16" style="width: 66%" v-model="input" placeholder="请输入监测方案名称" clearable></el-input>
          <div class="sousuo" @click="sercchFn()">搜索</div>
        </div>
        <div class="monitorbox-l-btn m-t-big" @click="addFn">
          <img src="../../assets/img/gjh/add.png" /> 新增监测方案
        </div>
        <!-- 机构方案列表 -->
        <div v-loading="loadArr.left1">
          <template v-if="monitorData.length > 0">
            <div class="content m-t-big" v-for="(item, index) in monitorData" :key="index" :class="{
              content: true,
              selectContent: item.isSect == 1,
            }" @click.stop="setListFn(item, 1)">
              <div class="content-top">
                <div class="content-top-left">{{ item.name }}</div>
                <!-- <img @click.stop="addFn(item)" class="content-top-right" src="../../assets/img/gjh/jiahao.png" alt /> -->
              </div>
              <div style="margin-bottom: 0.875rem" class="content-content">
                <div class="content-content-delete" @click.stop="deleteFn(item)">
                  <img src="../../assets/img/gjh/shanchu.png" alt />删除
                </div>
                <div class="content-content-edit" @click.stop="selectPlanMonitor(item)">
                  <img src="../../assets/img/gjh/bianji.png" alt />编辑
                </div>
                <div class="content-content-stop" v-if="item.status == 1" style="color: #ff8000"
                  @click="updateFn(item, 0)">
                  <img src="../../assets/img/gjh/tingzhi.png" alt />已停止
                </div>
                <div class="content-content-stop" v-if="item.status == 0" style="color: #02bc7c"
                  @click="updateFn(item, 1)">
                  <img src="../../assets/img/gjh/yunxing.png" alt />运行中
                </div>
              </div>
              <!-- <div v-for="value in item.childs" @click.stop="setListFn(value, 1)" :key="value.id" :class="{
                  'content-bottom': true,
                }">
                <div class="content-top">
                  <div class="content-top-left" v-html="value.name"></div>
                </div>
                <div class="content-content">
                  <div class="content-content-delete" @click="deleteFn(value)">
                    <img src="../../assets/img/gjh/shanchu.png" alt />删除
                  </div>
                  <div class="content-content-edit" @click="selectPlanMonitor(value)">
                    <img src="../../assets/img/gjh/bianji.png" alt />编辑
                  </div>
                  <div class="content-content-stop" v-if="value.status == 1" style="color: #ff8000" @click="updateFn(value, 0)">
                    <img src="../../assets/img/gjh/tingzhi.png" alt />已停止
                  </div>
                  <div class="content-content-stop" v-if="value.status == 0" style="color: #02bc7c" @click="updateFn(value, 1)">
                    <img src="../../assets/img/gjh/yunxing.png" alt />运行中
                  </div>
                </div>
              </div> -->
            </div>
          </template>
          <bitmap :desc="'机构方案'" v-else></bitmap>
        </div>

        <el-divider class="m-t-32" style="width: 65%; margin: 0 auto">公共方案</el-divider>
        <!-- 公共方案列表 -->
        <div v-loading="loadArr.left2">
          <template v-if="publicData.length > 0">
            <div class="content m-t-big" v-for="item in publicData" :key="item.id" @click="setListFn(item, 2)">
              <div class="content-top">
                <div class="content-top-left">{{ item.name }}</div>
                <img src="../../assets/img/gjh/fangan.png" alt />
              </div>
            </div>
          </template>
          <bitmap :desc="'公共方案'" v-else></bitmap>
        </div>
      </div>

      <RightList ref="right" @rightFn="planMonitorDataList" @setUpdateFn="setUpdateFn"
        @selectPlanMonitor="selectPlanMonitor" @deletePlan="deletePlan" :publicData="publicData"
        :monitorData="monitorData" />
    </div>
    <!-- 新建方案 -->
    <LeftDialog ref="LeftDialog" @savePlanMonitor="savePlanMonitor"></LeftDialog>
    <!-- 删除确认框 -->
    <deleteDialog ref="openDialog" @deleteFn="deletePlan"></deleteDialog>
    <!-- 导出框 -->
    <exportDialog ref="exportDialog"></exportDialog>
  </div>
</template>

<script>
import bitmap from "../../components/bitmap.vue";
import exportDialog from "../../components/exportDialog.vue"; // 导出弹框
import deleteDialog from "../../components/deleteDialog.vue";
import RightList from "./components/right.vue";
import {
  list,//左侧列表
  updateStartupById,
  updateShutdownById,
  deletePlan,
  planMonitorDataList,
  collect,
  savePlanMonitor,
  selectPlanMonitor,
  updatePlanMonitor,
  exportExcel,
} from "../../api/monitor/index";
import Pagination from "@/components/pagination.vue";
import LeftDialog from "./components/left.vue";
const cityOptions = ["上海", "北京", "广州", "深圳"];
export default {
  components: {
    Pagination,
    LeftDialog,
    deleteDialog,
    RightList,
    exportDialog,
    bitmap,
  },
  data() {
    return {
      options: [
        { value: 0, label: "不预警" },
        { value: 1, label: "关键词预警" },
      ],
      plansType: 1, // 检测方案（1机构方案，2公共方案）
      publicData: [],
      monitorData: [], // 方案列表
      isIndeterminate: true,
      checkAll: false,
      checkedCities: ["上海", "北京"],
      cities: cityOptions,
      secrchValue: "",
      timeArr: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)], // 时间
      tablecheckAll: false,
      checkedCities1: [],
      input: "",
      value: null,
      total: 0,
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      dataList: [], // 数据列表
      headInfo: null, // 从平台台账跳进来的
      loadArr: {
        // 加载
        left1: true,
        left2: true,
      },
    };
  },
  created() {
    // 如果是别的页面点进来的 就会有值
    let params = this.$route.query.info
      ? JSON.parse(decodeURIComponent(this.$route.query.info))
      : null;
    console.log(params);
    if (params && !params.isIndex) {//不是从首页进来的
      const { accountId, platformType } = params;
      this.headInfo = { accountId, platformType: [platformType + ""] };
    }
    if (params && params.isIndex) {//  从首页进来的
      this.params = params
      console.log(this.params);
    }
    this.queryPage(); // 方案列表
  },
  methods: {
    // 监测方案列表
    async queryPage(obj) {

      this.loadArr.left1 = true;//机构方案
      this.loadArr.left2 = true;//公共方案
      try {
        const { plansType } = this;//1机构方案,2公共方案
        const item = { plansType };
        let page = { limit: this.page.pageSize, page: this.page.pageNumber };
        list(obj ? { ...obj, ...item } : item).then((Res) => {
          // const { data, code } = Res.data;
          if (Res && Res.data && Res.data.code == 200) {
            const { data } = Res.data
            this.monitorData = data || [];
            this.loadArr.left1 = false;
            // 如果机构方案的数据长度为0 或者不存在 那就终止
            if (data.length > 0 || !data) {
              this.$refs.right.getData(this.monitorData[0], 1);//右侧header数据传过去
              this.planMonitorDataList({
                relationId: this.monitorData[0].id,
                ...page,
              });
            }
          }
        });
        await this.$main.mySetTimeOut(200);
        const item2 = { plansType: 2 };
        list(obj ? { ...obj, ...item2 } : item2).then((Res2) => {
          if (Res2 && Res2.data && Res2.data.code == 200) {
            this.publicData = Res2.data.data;
            this.loadArr.left2 = false;
            // 如果机构方案没有数据  那就显示公共方案的数据
            if (this.monitorData.length == 0 && this.publicData.length > 0) {
              console.log('进来了', this.monitorData, this.publicData,);
              this.$refs.right.getData(this.publicData[0], 1);
              this.planMonitorDataList({
                relationId: this.publicData[0].id,
                ...page,
              });
            } else {
              this.$refs.right.ifSkeleton = false
            }
          }
        });
        if (this.params.isIndex) {
          await this.$main.mySetTimeOut(500);
          let arr = [...this.monitorData, ...this.publicData,] // 方案列表
          let obj = arr.filter(v => v.name == this.params.name)[0]
          this.setListFn(obj, obj.plansType)
        }
      } catch {
        await this.$main.mySetTimeOut(500);
        this.loadArr.left1 = false;
        this.loadArr.left2 = false;
      }
    },
    // 搜索监测方案
    sercchFn() {
      const { value, input } = this;

      const obj = {
        name: input,
      };
      if (value || value === 0) obj.warnType = Number(value)
      this.queryPage(obj);
    },
    // 子组件请求的启用或者停用
    setUpdateFn(obj) {
      this.updateFn({ id: obj.id }, obj.status);
    },
    // 启用 || 停用
    async updateFn({ id }, status) {
      // status  0:开始启用  1：开始停止
      const Res =
        status === 1
          ? await updateShutdownById({ id, status })
          : await updateStartupById({ id, status });
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("修改成功！");
        this.queryPage();
      }
    },
    // 删除弹框
    deleteFn(item) {
      this.$refs.openDialog.openDialog(item);
    },
    // 删除方案
    async deletePlan({ item: { id } }) {
      const Res = await deletePlan(id);
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("删除方案成功！");
        // 假设this.currentPage是当前页码，this.pageSize是每页显示的数据条数，this.total是数据总条数
        const currentPageDataCount = this.total - (this.page.pageNumber - 1) * this.page.pageSize;
        if (currentPageDataCount <= 1 && this.page.pageNumber > 1) {
          // 如果当前页是最后一页且数据条数小于等于1，则跳转到上一页
          this.page.pageNumber -= 1;
        }
        this.queryPage();
      }
    },
    // 新增监测方案 or 打开弹框
    addFn(item) {
      this.$refs.LeftDialog.dakai(1, this.monitorData, item.id); // type 1：保存 2：编辑
    },
    // 提交  || 编辑
    async savePlanMonitor({ PlanData, type }) {
      const { directionalList, keywordsList, planMonitor, warnList } = PlanData;
      planMonitor.agencyName = JSON.parse(window.localStorage.getItem('user')).orgName // 机构名字
      planMonitor.orgId = JSON.parse(window.localStorage.getItem('user')).orgId  // 机构id
      const data = { directionalList, keywordsList, planMonitor, warnList };
      // type 1：保存 2：编辑
      const Res =
        type == 1 ? await savePlanMonitor(data) : await updatePlanMonitor(data);
      const { code, } = Res.data;
      if (code == 200) {
        this.queryPage();
        this.$message.success(type == 1 ? "新增方案成功！" : "编辑方案成功！");
        this.$refs.LeftDialog.handleClose();
      } else {
        this.$refs.LeftDialog.PlanData.planMonitor.parentId = null
      }
    },
    // 打开编辑 || 回显
    async selectPlanMonitor({ id }, type) {
      const Res = await selectPlanMonitor(id);
      const { code, data } = Res.data;
      if (code == 200) {
        this.$refs.LeftDialog.dakai(2, this.monitorData); // type 1：保存 2：编辑

        type
          ? this.$refs.LeftDialog.getMyinfo(data, id)
          : this.$refs.LeftDialog.getMyinfo(data);
      } else {
        this.$message.error("打开编辑失败！");
      }
    },
    // 点击方案调用数据列表
    async setListFn(item, planType) {
      this.$refs.right.getData(item, planType);
      await this.$main.mySetTimeOut(200);
      // this.planMonitorDataList({ relationId: item.id });
      this.$refs.right.resetFn();
      // this.$refs.right.rightOk()
    },
    // 数据列表
    async planMonitorDataList(obj, reportType) {
      const { headInfo } = this;
      let page = { limit: 10, page: 1 };
      if (headInfo) {
        // 从问题平台跳进来的
        obj = { ...obj, ...headInfo };
        page = { ...page, ...headInfo };
      }
      // 导出报告
      if (reportType == 1) {
        this.exportFN(obj);
        this.$refs.right.reportType = false;
        return;
      }
      try {
        const Res = await planMonitorDataList(obj ? obj : page);
        const { code, data } = Res.data;
        if (code == 200) {
          this.$refs.right.getList(data, headInfo);
        } else {
          this.$refs.right.dataList = []
          this.$refs.right.ifSkeleton = false
        }
      } catch {
        this.$refs.right.ifSkeleton = false
      }

    },
    // 导出
    async exportFN(obj) {
      //                        请求函数，参数配置,组件配置                            // 后面的1代表新的导出
      await this.$main.exportFN(exportExcel, obj, this.$refs, 1, '监测方案导出.xlsx', 1);
    },
    // 收藏
    async collect({ id }) {
      await collect(id);
    },
    search() {
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange() { },
    handleCheckedCitiesChange1() {
    },
  },
};
</script>

<style lang="scss" scoped>
.monitorbox {
  width: 100%;
  height: auto;

  // display: flex;
  // justify-content: flex-start;
  .monitorbox-l {
    float: left;
    width: 432px;
    height: auto;
    padding: 24px;
    background: #ffffff;

    .monitorbox-l-head {
      display: flex;
      justify-content: space-between;
      position: relative;

      .sousuo {
        width: auto;
        height: 90%;
        position: absolute;
        display: flex;
        align-items: center;
        right: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #f58030;
        cursor: pointer;
      }
    }

    .monitorbox-l-btn {
      width: 100%;
      height: 36px;
      line-height: 36px;
      background: #f58030;
      border-radius: 4px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .content {
    padding: 16px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 16px;

    .content-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-top-left {
        max-width: 290px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }

      .content-top-right {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .content-content {
      display: flex;
      align-items: center;
      margin-top: 24px;

      img {
        margin-right: 2px;
      }

      .content-content-delete {
        font-size: 14px;
        color: #ea3342;
        display: flex;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;
      }

      .content-content-edit {
        font-size: 14px;
        color: #2e59ec;
        display: flex;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;
      }

      .content-content-function {
        font-size: 14px;
        color: #02bc7c;
        display: flex;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;
      }

      .content-content-stop {
        font-size: 14px;
        color: #ff9a16;
        display: flex;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;
      }
    }

    .content-bottom {
      width: 100%;
      background: #f6f7fa;
      border-radius: 4px;
      padding: 16px;
      margin-top: 10px;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    }

    .content-bottom:hover {
      box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.14);
    }

    .contentshad2 {
      box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.08);
    }
  }

  .selectContent {
    box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.08);
  }

  .content:hover {
    box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.08);
  }
}

.dxpt {
  display: flex;
  justify-content: flex-start;

  .dxpt-l {
    flex: 1;
  }
}

.zxptList {
  padding: 16px;
  background: #f5f6fa;
  display: flex;
  justify-content: flex-start;

  .red {
    display: flex;
    align-items: center;
    color: #ea3342;
    cursor: pointer;
  }
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .flex-l {
    flex: 1;
  }

  .flex-r {
    flex: 1;
  }

  .yellow {
    display: flex;
    align-items: center;
    color: #f58030;
    cursor: pointer;
  }

  .red {
    display: flex;
    align-items: center;
    color: #ea3342;
    cursor: pointer;
    width: 100px;
  }
}
</style>