<template>
  <div class="monitorbox-r m-l-16">
    <bitmap v-if="publicData.length == 0 && monitorData.length == 0" style="margin-top: 0;"></bitmap>
    <div v-else>
      <div class="monitorbox-r-head">
        <div class="monitorbox-r-hea-t">
          <div class="monitorbox-r-hea-t-l">
            <span>{{ myData.name }}</span> （{{ total }}条）
          </div>
          <div class="monitorbox-r-hea-t-r" v-show="planType == 1">
            <div @click="deleteFn(myData, 8)">
              <img src="../../../assets/img/gjh/shanchu-b.png" /> 删除
            </div>
            <div class="m-l-32" @click="selectPlanMonitor(myData)">
              <img src="../../../assets/img/gjh/bianji-b.png" /> 编辑
            </div>
            <div class="m-l-32" style="color: #ffffff" v-if="myData.status == 1" @click="updateFn(myData, 0)">
              <img src="../../../assets/img/gjh/tingzhi-b.png" /> 已停止
            </div>
            <div class="m-l-32" style="color: #ffffff" v-if="myData.status == 0" @click="updateFn(myData, 1)">
              <img src="../../../assets/img/gjh/yunxing2.png" /> 运行中
            </div>
          </div>
        </div>
        <div class="monitorbox-r-hea-b m-t-16">
          <span>创建时间：{{ myData.create_time }}</span>
          <span style="margin-left: 7.5rem">最后更新：{{ myData.update_time }}</span>
        </div>
      </div>
      <!-- 筛选 || 表格 -->
      <div class="monitorbox-r-box m-t-16">
        <!-- 筛选 -->
        <div class="monitorbox-r-box-t">
          <!-- 平台类型 -->
          <PlatformCom ref="platform_type" :desc="'platform_type'"></PlatformCom>
          <!-- 主体类型 -->
          <PlatformCom ref="body_type" :desc="'body_type'"></PlatformCom>
          <!-- 状态 -->
          <div class="searchBox-box" v-if="planType == 1">
            <div class="searchBox-box-L">状态</div>
            <div class="searchBox-box-R m-l-24">
              <el-radio-group v-model="stateObj.checkedform">
                <el-radio :label="item.value" v-for="item in stateObj.checkedData" :key="item.value">{{ item.label
                  }}</el-radio>
              </el-radio-group>
              <el-checkbox class="m-l-32" v-model="isWarn">已预警</el-checkbox>
              <el-checkbox v-model="isCollect">已收藏</el-checkbox>
            </div>
          </div>
          <!-- 标签 or 时间 -->
          <div class="searchBox-box text searchBox-flex">
            <div class="searasdaschBox-flex-l">
              <el-select style="min-width: 6.25rem; max-width: 9.375rem" v-model="myinfo.reportVlaue" placeholder="文章标题">
                <el-option v-for="item in reportTypes" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input class="m-l-16" style="width: 15rem" v-model="myinfo.secrchValue" clearable=""
                placeholder="请输入"></el-input>
              <span class="timefont m-l-32"> 时间 </span>
              <el-date-picker class="m-l-16" value-format="yyyy-MM-dd HH:mm:ss" v-model="myinfo.timeArr"
                type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
              <!-- <el-date-picker
              class="m-l-16"
              v-model="myinfo.timeArr"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              range-separator="—"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker> -->
            </div>
            <div class="searchBox-flex-r">
              <div class="btn" @click="rightOk">查询</div>
              <div class="btn bai m-l-16" @click="resetFn">重置</div>
            </div>
          </div>
        </div>
        <!-- 表格 -->
        <div v-loading="ifSkeleton">
          <div class="monitorbox-r-box-c m-t-16">
            <!-- 全选 选中和总量显示 -->
            <div class="table-header" v-show="dataList.length > 0">
              <div class="table-header-L">
                <el-checkbox :indeterminate="isGjhIndeterminate" v-model="tablecheckAllGjh"
                  @change="handleCheckAllChange($event)">全选</el-checkbox>
                <span class="m-l-24">选中{{ isGjhList.length }}条 &nbsp;</span>/
                &nbsp;共{{ dataList.length }}条信息
              </div>
              <div class="table-header-R">
                <div class="y" v-if="planType == 1" @click="readFnAll(isGjhList)">
                  一键已读
                </div>
                <div class="d m-l-24" @click="exportDakai">数据导出</div>
                <div class="s m-l-24" v-if="planType == 1" @click="deleteFn(isGjhList, 7)">
                  选中删除
                </div>
              </div>
            </div>
            <!-- 表格内容 -->
            <div class="tableBody">
              <!-- <el-skeleton :rows="18" animated :loading="ifSkeleton" /> -->
              <div class="tablebox" v-for="(item, index) in dataList" :key="index">
                <div class="tablebox-t">
                  <div class="tablebox-t-l">
                    <el-checkbox v-model="item.isGjh" @change="handleCheckedCitiesChange(item)"></el-checkbox>

                    <!-- <div class="biaoji m-l-16">
                    {{ item.platformName ? item.platformName : "" }}
                  </div> -->
                    <div class="yujin m-l-16" v-if="item.warningOrgIds">
                      已预警
                    </div>
                    <!-- <div class="yujin m-l-16" v-else>未预警</div> -->
                    <div class="texthidden desc m-l-16" v-html="item.title ? item.title : ''" @click="goDetail(item)">
                    </div>
                  </div>
                  <template v-if="planType == 1">
                    <div class="tablebox-t-r red" v-if="item.isRead == 1">
                      <img width="16px" src="../../../assets/img/wang/yidu.png" alt="" srcset="" />
                      <span style="color: #24bb87">已读</span>
                    </div>
                    <div class="tablebox-t-r red" v-else>
                      <img width="16px" src="../../../assets/img/wang/weidu.png" alt="" srcset="" />
                      <span>未读</span>
                    </div>
                  </template>
                </div>
                <div class="tablebox-c textHidden5" v-html="item.content ? item.content : ''"></div>
                <div class="tablebox-b">
                  <div class="tablebox-b-t">
                    <div class="tablebox-b-t-l flex-c">
                      <div class="max-w tiao-account texthidden" @click="$main.goTaiZhang(item)"
                        v-if="item.platformType == 1">
                        信息来源：{{ item.source || "无" }}
                      </div>
                      <div class="max-w tiao-account texthidden" @click="$main.goTaiZhang(item)" v-else>作者：{{
                        item.author || "无" }}
                      </div>
                      <div class="m-l-40">
                        所在地区：{{ item.areaName || "无" }}
                      </div>
                      <div class="m-l-40">发布时间：{{ item.releaseTime }}</div>
                    </div>
                    <!-- <div class="time-end">2020年6月2日 12:00:00</div> -->
                  </div>
                  <div class="tablebox-b-b m-t-big">
                    <template v-if="planType == 1">
                      <div v-if="item.collectOrgIds" @click="cancelCollect(item)">
                        <img src="@/assets/img/zhang/yishoucang.png" alt="" class="m-r-8" />
                        已收藏
                      </div>
                      <div @click="collect(item)" v-else>
                        <img src="@/assets/img/gjh/collection.png" alt="" class="m-r-8" />
                        收藏
                      </div>
                    </template>

                    <div class="m-l-40" @click="$main.copyFun(item.joiningTogether, '复制全部')">
                      <img src="../../../assets/img/gjh/fz.png" alt="" class="m-r-8" />复制全部
                    </div>
                    <div class="m-l-40" @click="
                      $main.copyFun(item.webSiteAndTitle, '复制链接和标题')
                      ">
                      <img src="../../../assets/img/gjh/copy-link.png" alt="" class="m-r-8" />复制链接和标题
                    </div>
                    <div class="m-l-40" @click="deleteFn(item)" v-if="planType == 1">
                      <img src="../../../assets/img/gjh/del.png" alt="" class="m-r-8" />删除
                    </div>
                  </div>
                </div>
              </div>

              <!-- <el-skeleton :rows="18" animated :loading="ifSkeleton" /> -->

              <!-- 分页 -->
              <Pagination v-show="dataList.length > 0" :pageNumber="page.page" :pageSize="page.limit" :total="total"
                @CurrentChange="CurrentChange" @SizeChange="SizeChange">
              </Pagination>
            </div>
          </div>
          <bitmap v-if="dataList.length == 0" :desc="'列表'"></bitmap>
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="deleteData"></deleteDialog>
  </div>
</template>

<script>
import PlatformCom from "../../../components/dressing/PlatformCom.vue";
import bitmap from "../../../components/bitmap.vue";
import deleteDialog from "@/components/deleteDialog.vue";
import Pagination from "@/components/pagination.vue";
import {
  collect,
  deleteData,
  cancelCollect,
  read,
} from "../../../api/monitor/index";
export default {
  props: {
    publicData: {
      type: Array,
    },
    monitorData: {
      type: Array,
    }

  },
  components: {
    Pagination,
    deleteDialog,
    bitmap,
    PlatformCom,
  },
  data() {
    return {
      planType: 1,
      isGjhList: [],
      isGjhIndeterminate: false, // 半选
      tablecheckAllGjh: false, // 全选
      isCollect: false, // 已收藏
      isWarn: false, // 已预警
      // 状态
      stateObj: {
        checkedform: null, //主体类型已选
        checkedData: [
          { value: 1, label: "已读" },
          { value: 0, label: "未读" },
          { value: 2, label: "24小时未读" },
        ], //主体类型
      },
      myData: {}, //
      ifSkeleton: true, // 加载中
      myinfo: {
        // 配置 or 输入
        reportVlaue: 1, //选择类型
        timeArr: [],
        secrchValue: "", // 输入内容
      },
      // 搜索栏里 类型选择框
      reportTypes: [
        {
          //
          value: 1,
          label: "文章标题",
        },
        {
          value: 2,
          label: "文章内容",
        },
        {
          value: 3,
          label: "信息来源",
        },
      ],
      total: 100, // 总数
      //  分页
      page: {
        limit: 10,
        page: 1,
      },
      dataList: [], // 数据列表
      reportType: false, // 是否导出
    };
  },
  methods: {
    // 导出打开
    exportDakai() {
      this.reportType = true;
      this.rightOk();
    },

    // 去详情页
    goDetail(item) {
      this.$main.routeResolve("/monitorDetail", item);
    },
    // 一键已读
    async readFnAll(list) {
      if (list.length == 0) {
        this.$message("请先选择要已读的数据！");
        return;
      }
      const { relationId, databaseId } = list[0];
      const ids = list.map((v) => v.id);
      const Res = await read({ ids, relationId, databaseId });
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("一键已读！");
        this.dataList.forEach((v) => {
          list.forEach((i) => {
            if (i.id == v.id) v.readOrgIds = "1111";
          });
        });
        // this.rightOk()
      }
    },
    // 列表全选
    handleCheckAllChange(val) {
      if (val) {
        this.dataList.forEach((v) => {
          v.isGjh = true;
        });
      } else {
        this.dataList.forEach((v) => {
          v.isGjh = false;
        });
      }
      this.isGjhList = this.dataList.filter((v) => v.isGjh);
      this.isGjhIndeterminate = false;
    },
    // 列表多选
    handleCheckedCitiesChange() {
      const { dataList } = this;
      const isGjhs = dataList.filter((v) => v.isGjh);
      this.isGjhList = isGjhs;
      this.tablecheckAllGjh = isGjhs.length === dataList.length; // 全选为true
      this.isGjhIndeterminate =
        isGjhs.length > 0 && isGjhs.length < dataList.length; // isIndeterminateOne：半选
    },
    // 启用 || 停用
    async updateFn({ id }, status) {
      this.$emit("setUpdateFn", { id, status });
    },
    //编辑
    selectPlanMonitor(obj) {
      this.$emit("selectPlanMonitor", obj);
    },
    // 提交
    rightOk({ ifPageType } = {}) {
      if (!this.reportType) {
        this.ifSkeleton = true;
        this.isGjhList = [];
        this.isGjhIndeterminate = false;
        this.tablecheckAllGjh = false;
      }
      //  pageType 存在 代表是分页按钮点击的  如果不存在 那么分页默认第一页
      if (!ifPageType) {
        this.page.page = 1;
      }
      const {
        myinfo: {
          reportVlaue, //选择类型
          secrchValue, // 输入内容
          timeArr,
        },
        page,
        stateObj,
        myData: { id },
        isCollect,
        isWarn,
        $refs,
      } = this;

      let obj = {
        startTime: timeArr[0],
        endTime: timeArr[1],
        relationId: id,
      };
      if (!this.reportType) obj = { ...obj, ...page };
      if (isCollect) obj.isCollect = 1;
      if (isWarn) obj.isWarn = 1;
      if ($refs.platform_type.formObj.checkedform.length > 0)
        obj.platformType = $refs.platform_type.formObj.checkedform.map(
          (item) => item.value
        );
      if ($refs.body_type.formObj.checkedform.length > 0)
        obj.bodyType = $refs.body_type.formObj.checkedform.map(
          (item) => item.value
        );

      if (stateObj.checkedform || stateObj.checkedform == 0)
        obj.isRead = stateObj.checkedform; // 已读 || 未读
      if (reportVlaue == 1 && secrchValue.length > 0) obj.title = secrchValue;
      if (reportVlaue == 2 && secrchValue.length > 0) obj.content = secrchValue;
      if (reportVlaue == 3 && secrchValue.length > 0) obj.source = secrchValue;
      console.log(obj);
      this.$emit("rightFn", obj, this.reportType);
    },
    // 重置
    resetFn() {
      this.ifSkeleton = true;
      const { myinfo, page, isCollect, isWarn, stateObj } = this;
      // 批量处理重置
      this.$main.setReset(
        {
          myinfo,
          page,
          isCollect,
          isWarn,
          stateObj,
        },
        this
      );
      this.$refs.platform_type.resetCom(); // 平台类型
      this.$refs.body_type.resetCom(); // 主体类型
      this.rightOk();
    },
    // 获取列表
    async getList({ list, totalCount }, headInfo = null) {
      if (headInfo) {
        // 从问题平台跳进来
        this.$refs.platform_type.setData(headInfo.platformType); //  回显平台类型多选框
      }
      await this.$main.mySetTimeOut(500);
      this.ifSkeleton = false;
      list.forEach((v) => {
        v.isGjh = false;
      });
      this.dataList = this.$main.listImgNo(list);
      // this.queryList = 
      this.total = Number(totalCount);
    },
    getData(data, planType) {
      this.myData = data || {};
      this.planType = planType;
    },
    // 收藏
    async collect(item) {
      const Res = await collect(item.id);
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("已收藏！");
        item.collectOrgIds = "有点东西"; // 别删  后端规定的 有点东西的后端
      }
    },
    // 取消收藏
    async cancelCollect(item) {
      const Res = await cancelCollect(item.id);
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("取消收藏成功！");
        item.collectOrgIds = null;
      }
    },
    deleteFn(item, type) {
      if (type == 7) {
        if (item.length == 0) {
          this.$message("请先选择一条要删除的数据");
          return;
        }
      }
      item.message = "本条数据";
      this.$refs.openDialog.openDialog(item, type);
    },
    // 删除
    async deleteData({ item, type }) {
      let dataDto = {};
      // 批量
      if (type == 7) {
        const { relationId } = item[0];
        const ids = item.map((v) => v.id);
        dataDto = {
          ids,
          relationId,
        };
        const {
          data: { code },
        } = await deleteData(dataDto);
        if (code == 200) {
          this.$message.success("已删除！");
          await this.$main.mySetTimeOut(1000);
          this.rightOk();
        }
      }
      // 单个
      if (type == 2) {
        const { id, relationId } = item;
        dataDto = {
          ids: [id],
          relationId,
        };
        const {
          data: { code },
        } = await deleteData(dataDto);
        if (code == 200) {
          this.$message.success("已删除！");
          await this.$main.mySetTimeOut(1000);
          this.rightOk();
        }
      }
      if (type == 8) {
        this.$emit("deletePlan", { item });
      }
      // 列表上删除后 取消所有勾选框
      // if(type == 7 || type == 2) {
      //   this.dataList.forEach((v) => {
      //     v.isGjh = false;
      //   });
      // }
    },
    CurrentChange(val) {
      this.page.page = val;
      this.rightOk({ ifPageType: 1 });
    },
    SizeChange(val) {
      this.page.limit = val;
      this.page.page = 1;
      this.rightOk();
    },
  },
};
</script>

<style lang="scss" scoped>
.monitorbox-r {
  // flex: 1;
  float: right;
  width: calc(100% - 452px);

  .monitorbox-r-head {
    width: 100%;
    height: 121px;
    background: url("../../../assets/img/gjh/headBG.png") no-repeat;
    background-size: 100% 100%;
    padding: 24px;
    color: #ffffff;

    .monitorbox-r-hea-t {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      .monitorbox-r-hea-t-l {
        span {
          font-size: 26px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
      }

      .monitorbox-r-hea-t-r {
        display: flex;
        justify-content: flex-start;

        div {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin-right: 5px;
          }
        }
      }
    }

    .monitorbox-r-hea-b {
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
    }
  }

  .monitorbox-r-box {
    padding: 16px 24px 24px 24px;
    border-radius: 8px;
    background: #ffffff;
    width: 100%;
    // min-height: 971px;

    .monitorbox-r-box-t {
      .searchBox-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #e4e6ec;

        .timefont {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
        }

        .searchBox-box-L {
          text-align: right;
          width: 60px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #999999;
        }

        .searchBox-box-R {
          display: flex;
          align-items: center;
        }
      }

      .searchBox-flex {
        display: flex;
        justify-content: space-between;

        .searchBox-flex-r {
          display: flex;
          justify-content: flex-start;

          .bai {
            background: #ffffff;
            color: black;
            border: 1px solid #e1e1e1;
          }
        }
      }
    }

    .monitorbox-r-box-c {
      .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333333;
        padding-bottom: 16px;

        .table-header-L {
          display: flex;
          justify-content: flex-start;

          span {
            color: #f58030;
          }
        }

        .table-header-R {
          display: flex;
          flex-direction: row;

          div {
            padding: 8px 16px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;
          }

          .d {
            background: #fff1e8;
            color: #ed731f;
          }

          .s {
            color: #ea3342;
            background: #fff2f3;
          }

          .y {
            background: #ffffff;
            color: black;
            border: 1px solid #e1e1e1;
          }
        }
      }

      .tableBody {
        .tablebox {
          border-bottom: 1px solid #e4e6ec;
          padding: 0 0 16px 0;

          .tablebox-t {
            display: flex;
            justify-content: space-between;
            padding: 16px 0;

            .tablebox-t-l {
              width: 95%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
              color: #333333;

              .biaoji {
                font-size: 14px;
                color: #ffffff;
                font-weight: 500;
                padding: 4px 8px;
                background: #f58030;
              }

              .yujin {
                background: #ffedef;
                font-size: 14px;
                color: #ea3342;
                font-weight: 500;
                padding: 4px 8px;
              }

              .desc {
                max-width: 85%;
                cursor: pointer;
              }
            }

            .tablebox-t-r {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;

              span {
                margin-left: 6px;
              }
            }

            .red {
              color: #ea3342;
            }
          }

          .tablebox-c {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }

          .tablebox-b {
            padding: 16px 0 0 0;
            // display: flex;
            // justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            .tablebox-b-t {
              color: #666666;
              display: flex;
              justify-content: space-between;

              .tablebox-b-t-l {
                display: flex;
                justify-content: flex-start;
              }
            }

            .tablebox-b-b {
              display: flex;
              justify-content: flex-end;

              div {
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.content {
  padding: 16px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 16px;

  .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-top-left {
      max-width: 290px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }

    .content-top-right {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .content-content {
    display: flex;
    align-items: center;
    margin-top: 24px;

    img {
      margin-right: 2px;
    }

    .content-content-delete {
      font-size: 14px;
      color: #ea3342;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
    }

    .content-content-edit {
      font-size: 14px;
      color: #2e59ec;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
    }

    .content-content-function {
      font-size: 14px;
      color: #02bc7c;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
    }

    .content-content-stop {
      font-size: 14px;
      color: #ff9a16;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .content-bottom {
    width: 100%;
    background: #f6f7fa;
    border-radius: 4px;
    padding: 16px;
    margin-top: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
  }

  .content-bottom:hover {
    box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.14);
  }

  .contentshad2 {
    box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.08);
  }
}

.content:hover {
  box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.08);
}

.dxpt {
  display: flex;
  justify-content: flex-start;

  .dxpt-l {
    flex: 1;
  }
}

.zxptList {
  padding: 16px;
  background: #f5f6fa;
  display: flex;
  justify-content: flex-start;

  .red {
    display: flex;
    align-items: center;
    color: #ea3342;
    cursor: pointer;
  }
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .flex-l {
    flex: 1;
  }

  .flex-r {
    flex: 1;
  }

  .yellow {
    display: flex;
    align-items: center;
    color: #f58030;
    cursor: pointer;
  }

  .red {
    display: flex;
    align-items: center;
    color: #ea3342;
    cursor: pointer;
    width: 100px;
  }
}
</style>